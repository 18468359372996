import React, {useState} from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import {BLOCKS, MARKS, INLINES} from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Accordion, Card, Col, Container, Row } from "react-bootstrap";
import CaseStudies from "../components/CaseStudies";
import BookMeetHomePage from '../components/BookMeetHomePage'
import Quote from '../components/Quote'
import { Plus, Dash } from "react-bootstrap-icons";
import LeftImg from "../components/LeftImg";
import RightImg from "../components/RightImg";
import TwoImageBlocks from "../components/TwoImageBlocks";
import TwoTextBlocks from "../components/TwoTextBlocks";
import ShareButtons from "../components/sharebuttons";
// import CtaLead from "../components/CtaLead";
import Tweet from "../components/Tweet";
import Facebook from "../components/Facebook";
import { Seo } from "../components/Seo";
import { useLocation } from "@reach/router"



import {PortableText} from '@portabletext/react'
import urlBuilder from '@sanity/image-url'
import {getImageDimensions} from '@sanity/asset-utils'

const ComponentName = ({ 
    data:{caseStudies:
        {csTitle, 
        bannerLogo, 
        csImage, 
        description,
        metaTitle,
        image,
        accordionTitle,
        firstBoxDesc,
        firstBoxNumber,
        secondBoxDesc,
        secondBoxNumber ,
        _rawContent:json,
        _rawProjectDetails:json1}} }) => {
        const cnt =json;
        const pr =json1;

    const slugComponentMap = {
        "contact-us": BookMeetHomePage,
        "quote": Quote,
        "left-img" : LeftImg,
        "right-img": RightImg,
        "two-image-blocks": TwoImageBlocks,
        "two-text-blocks" :TwoTextBlocks,
        "tweet" : Tweet,
        "facebook" : Facebook,
    };


    const pathLocation= useLocation();
    const url= pathLocation.href;
    // const url= typeof window !== "undefined" ? window.location.href : "";
    // const url= "https://sogody.com";
    const defaultInline = (type, node) => <span>type: {type} id: {node.data.target.sys.id}</span>;

    const options={
        renderNode:{
            [BLOCKS.EMBEDDED_ENTRY]:(node, children) => {
                const Comp = slugComponentMap[node.data.target.fields.slug['en-US']];
                const fields = node.data.target.fields;

                const props = Object.keys(fields).map(f => ({[f]: fields[f]['en-US']})).reduce((curr, acc) => ({...curr, ...acc}), {});
                return <Comp {...props} />
            },
            [INLINES.ASSET_HYPERLINK]: node => defaultInline(INLINES.ASSET_HYPERLINK, node),
            [INLINES.ENTRY_HYPERLINK]: node => defaultInline(INLINES.ENTRY_HYPERLINK, node),
            [INLINES.HYPERLINK]: (node) => {
				if (node.data.uri.indexOf('youtube.com') !== -1) {
					return (
						<div> 
							<iframe 
								id="ytplayer"
                                title="cs iframe"
								src={node.data.uri}
								type="text/html"
								width="640"
								height="360"
                                frameBorder="0"
                                // target="_blank"
                                rel="noreferrer"
								allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture ; fullscreen"
							/>
						</div>
					);
				} else
					return (
						<a
							href={node.data.uri}
							target="_blank"
						>
							{node.content[0].value}
						</a>
					);
			} ,
            // [INLINES.HYPERLINK]: (node, children) => <a href={node.data.uri} target="_blank">{children(node.content)}</a>,
            //[INLINES.ENTRY_HYPERLINK] : renderers.embeddedHyperLinkRenderer,
            // [INLINES.ENTRY_HYPERLINK]: (node, children) => {
    
            //     const referencedEntry = getEntryWithId(node.data.target.sys.id);

            //     return <a href={`/${node.data.target}`}>{children}</a>;
            //     },
            // [BLOCKS.EMBEDDED_ASSET]: renderers.embeddedAssetsRenderer,

        [BLOCKS.HEADING_1]:(node, children) =>
        (<h1 className="heading1">{children}</h1>),

        [BLOCKS.HEADING_2]:(node, children) =>
        (<h2 className="heading2">{children}</h2>),

        [BLOCKS.HEADING_3]:(node, children) =>
        (<h3 className="heading3">{children}</h3>),

        [BLOCKS.HEADING_4]:(node, children) =>
        (<h4 className="heading4">{children}</h4>),

        [BLOCKS.HEADING_5]:(node, children) =>
        (<h5 className="heading5">{children}</h5>),

        [BLOCKS.HEADING_6]:(node, children) =>
        (<h6 className="heading6">{children}</h6>),

        [BLOCKS.UL_LIST]:(node, children) =>
        (<ul className="ul-list">{children}</ul>),

        [BLOCKS.OL_LIST]:(node, children) =>
        (<ol className="ol-list">{children}</ol>),
        
        [BLOCKS.EMBEDDED_ASSET]:(node, children) =>
        (<img className="update-cnt-image" alt="Success stories img" src={`https:${node.data.target.fields.file["en-US"].url}`}/>),
        
        [BLOCKS.PARAGRAPH]:(node, children) =>
        (<p className="up-paragraph">{children}</p>),
        },

        [BLOCKS.QUOTE]: (node, children) => <blockquote>{children(node.content)}</blockquote>,
        [BLOCKS.HR]: () => <hr />,

        renderMark: {
            [MARKS.CODE]: code => <code>{code}</code>,
            // [MARKS.BOLD]: text => <b>{text} </b>, 
            // [MARKS.UNDERLINE]: text => <u>{text}</u>,
            [MARKS.ITALIC]: text => <i>{text}</i>,
        },
    } 

    const [open, setOpen] = useState(false); 

    const SampleImageComponent = ({value, isInline}) => {
        const {width, height} = getImageDimensions(value)
        return (
          <img
            src={urlBuilder({projectId:"3hfxs7a8",dataset:"production"})
              .image(value)
              .url()}
            alt={value.alt || ' '}
            className="update-cnt-image"
            loading="lazy"
            style={{
              // Display alongside text if image appears inside a block text span
              display: isInline ? 'inline-block' : 'block',
      
              // Avoid jumping around with aspect-ratio CSS property
            }}
          />
        )
      }

    const myPortableTextComponents = {
        block: {
          normal: ({children}) => <p className="up-paragraph">{children}</p>,
          h1: ({children}) => <h1 className="heading1">{children}</h1>,
          h2: ({children}) => <h2 className="heading2">{children}</h2>,
          h3: ({children}) => <h3 className="heading3">{children}</h3>,
          h4: ({children}) => <h4 className="heading4">{children}</h4>,
          h5: ({children}) => <h5 className="heading5">{children}</h5>,
          h6: ({children}) => <h6 className="heading6">{children}</h6>,
        },

        types: {
            image: SampleImageComponent,
        },

        list: {
            // Ex. 1: customizing common list types
            bullet: ({children}) => { return <ul className="ul-list">{children}</ul>},
            number: ({children}) => <ol className="ol-list">{children}</ol>,
        
            // Ex. 2: rendering custom lists
            checkmarks: ({children}) => <ol className="m-auto text-lg">{children}</ol>,
          },

          listItem: {
            // Ex. 1: customizing common list types
            bullet: ({children,value:{style}}) => <li>{style == "normal" ? <p className='up-paragraph'>{children}</p> : children}</li>,
            bullet: ({children,value:{style}}) => <li>{style == "normal" ? <p className='up-paragraph'>{children}</p> : children}</li>,
        
            // Ex. 2: rendering custom list items
          },
        
      
        marks: {
          link: ({children, value}) => {
            const rel = !value.href.startsWith('/') ? 'noreferrer noopener' : undefined
            return (
              <a href={value.href} rel={rel}>
                {children}
              </a>
            )
          },
        },
    }

    return <div  className="single-case-study">
        <Layout>
        <Seo 
            title={metaTitle} 
            description={description[0].children[0].text} 
            image={image?.asset?.url ?? csImage.asset.url}
            url={url}
        />
        <div className="banner"> 
            <Container className="up-container"> 
                {
                    bannerLogo && <div className="banner-logo">
                    <img src={bannerLogo.asset.url} alt="Success stories img"/> 
                </div>
                }   
                <h1 className="title">{csTitle}</h1>
                </Container>   
        </div>
        <div className="boxes">
            <Container className="up-container">
            <Row>
                <Col xs={12} md={6} lg={6}>
                    <div className="box">
                        <p className="highlight-number">{firstBoxNumber}</p>
                        <p className="highlight-desc">{firstBoxDesc}</p>
                    </div>
                </Col>
                <Col  xs={12} md={6} lg={6}>
                    <div className="box">
                        <p className="highlight-number">{secondBoxNumber}</p>
                        <p className="highlight-desc">{secondBoxDesc}</p>     
                    </div>
                </Col>
            </Row>
            </Container>
        </div>
        <div className="single-cs-content">
        <Container className="up-container"> 
            
            <div className="cs-img-content">
            {/* <img src={csImage.src}/> */}
                <img  className="case-study-img" src={csImage.asset.url} alt="Logo" />
            </div>
            { accordionTitle && <Accordion defaultActiveKey="1">
            <Card className="project-details">
                <Accordion.Toggle as={Card.Header} eventKey="0" onClick={() => setOpen(!open)}>
                    {accordionTitle} <span className="q-icon">{open!== false ? <Dash /> : <Plus />} </span>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                <Card.Body>
                    <PortableText
                        value={pr}
                        components={myPortableTextComponents}
                    />
                </Card.Body>
                </Accordion.Collapse>
            </Card>
            </Accordion>} 
            <div className="single-case">
                <PortableText
                        value={cnt}
                        components={myPortableTextComponents}
                    />
            </div>
            <BookMeetHomePage />
            <ShareButtons url={url ? url : " "} title={csTitle} summary={csTitle} body= {csTitle + "\n\n" + "Visit: " + url + "\n"}/>
        </Container>
        </div>
        <CaseStudies/>
    </Layout>
    </div>
}

export const query = graphql`
query GetSingleCaseStudy($slug:String){
    caseStudies:  sanityCaseStudies(csSlug: {eq: $slug}) {
        csBtn
        csTitle
        csSlug
        accordionTitle
        firstBoxDesc
        firstBoxNumber
        secondBoxDesc
        secondBoxNumber
        bannerLogo {
          asset {
            url
          }
        }
        csImage {
          asset {
            url
          }
        }
        _createdAt(formatString: "D MMMM YYYY")
        _rawContent
        _rawProjectDetails
        description{
          children{
            text
          }
        }
        metaTitle
        image{
          asset {
            url
          }
        }
      }
    }
`

export default ComponentName

